import React, { memo } from 'react'
import {
  BWPanel,
  BWText,
  CSS_GRID_TWO_COLUMNS,
  BWPageContent,
  CSS_FULL_WIDTH
} from 'src/libs/qb-brand-web-components'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { CSS_GRID_COLUMN_THREE } from '../../../styles/shared'

const ShopItem = ({
  label,
  description,
  fullColumn,
  customCss,
  ...restProps
}) => {
  return (
    <BWPanel
      gap={10}
      css={[
        CUSTOM_REDEEM_PANEL,
        fullColumn && [CSS_GRID_COLUMN_THREE, CSS_FULL_WIDTH],
        customCss
      ]}
      {...restProps}
    >
      <BWText alignCenter label={label} weight={700} />
      {description && <BWText alignCenter label={description} size={12} />}
    </BWPanel>
  )
}

export default memo(ShopItem)

export const ShopItemsPanel = ({ children, fullWidth }) => {
  return (
    <ShopItemsPanelContainer css={CSS_GRID_TWO_COLUMNS} fullWidth={fullWidth}>
      {children}
    </ShopItemsPanelContainer>
  )
}

const CUSTOM_REDEEM_PANEL = css`
  width: 170px;
  height: 170px;
  padding: 15px;
  place-content: center;
`

const ShopItemsPanelContainer = styled(BWPageContent)`
  ${({ fullWidth }) =>
    fullWidth
      ? CSS_FULL_WIDTH
      : `
max-height: 300px;
overflow-y: auto;
`}
`
